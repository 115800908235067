import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const _import_ = file => () =>
    import ('pages/' + file + '.vue')
export const constantRouterMap = [{
    path: '/',
    redirect: '/login',
}, {
    path: '/login',
    component: _import_('login/index'),
    name: 'Login',
    meta: {
        title: '登录',
        keepAlive: false,
        login: false
    }
}, {
    path: '/testRequestForm',
    component: _import_('testRequestForm/index'),
    name: 'TestRequestForm',
    meta: {
        title: '裕康医学检测申请',
        keepAlive: false,
        login: true
    }
}, {
    path: '/agrementDetail',
    component: _import_('agrementDetail/index'),
    name: 'AgrementDetail',
    meta: {
        title: '裕策隐私保护政策、检测知情同意书',
        keepAlive: true,
        login: false
    }
}, {
    path: '/submitFormSuccess',
    component: _import_('submitFormSuccess/index'),
    name: 'submitFormSuccess',
    meta: {
        title: '裕康医学检测申请',
        keepAlive: false,
        login: true
    }
}]
export const router = new Router({
    // mode: 'history',
    routes: constantRouterMap
})