<template>
  <div id="app">
    <router-view id="page"
                 v-if="$route.meta.keepAlive"></router-view>
    <router-view id="page"
                 v-else></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>
