/**
 * request.js
 * 通过promise对axios做二次封装，针对用户端参数，做灵活配置
 */
import { Toast } from 'vant';
import instance from './interceptor'
/**
 * 核心函数，可通过它处理一切请求数据，并做横向扩展
 * @param {url} 请求地址
 * @param {params} 请求参数
 * @param {options} 请求配置，针对当前本次请求；
 * @param loading 是否显示loading
 * @param error 本次是否显示错误
 */
Toast.allowMultiple();
function request(url, params, options = { loading: true, error: true }, method) {
    let loadingInstance;
    // 请求前loading
    if (options.loading) {
        loadingInstance = Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '加载中...',
        });
    }
    return new Promise((resolve,reject) => {
        let data = {}
        // get请求使用params字段
        if (method == 'get') data = { params }
        // post请求使用data字段
        if (method == 'post') data = { data: params }
        // 通过mock平台可对局部接口进行mock设置
        instance({
            url,
            method,
            ...data
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data);
                res.message && Toast({ type: 'success', message: res.message, duration: 3000,closeOnClick:true});
            } else {
                // 通过配置可关闭错误提示
                if (options.error) Toast({ type: 'fail', message: res.message, duration: 5000,closeOnClick:true });
                reject(res);
            }
        }).catch((error) => {
            console.log(error.message);
        }).finally(() => {
            loadingInstance.clear();
        })
    })
}
// 封装GET请求
function get(url, params, options) {
    return request(url, params, options, 'get')
}
// 封装POST请求
function post(url, params, options) {
    return request(url, params, options, 'post')
}
export default {
    get, post
}