/**
 * index.js
 * api地址管理
 */
//正式打包开启
//如果后端没配置nginx跨域开发开启

export default {
    login: `/olapi/v1/auth/login`, //肿瘤类型（癌种）列表
    sendSmsCcode: `/olapi/v1/auth/send-sms-vcode`,
    tumors: `/olapi/v1/kb/tumors`, //肿瘤类型（癌种）列表
    stages: `/olapi/v1/kb/stages`, //肿瘤分期（staging）列表
    drugs: `/olapi/v1/kb/drugs`, //药物列表
    regions: `/olapi/v1/kb/regions`, // 省市区地址
    relatives: `/olapi/v1/kb/relatives`, //亲属关系
    submitTestForm: `/olapi/v1/patient/info.rest`, //提交表单
}