import { router } from './index'
router.beforeEach((to, from, next) => { // 动态配置每个页面title
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.meta.login) { //判断前往的界面是否需要登陆

    }
    next();
})
export default router