import Vue from 'vue'
import App from './App.vue'
import router from './router/permission.js'
import 'assets/less/index.less'
import 'lib-flexible/flexible'
//加载dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";
//api相关
import request from './api/request'
import api from './api/index'
// 在原型上扩展,这样不用在每个页面都导入request
Vue.prototype.$request = request;
Vue.prototype.$api = api;
dayjs.extend(relativeTime);
// vant相关组件
import { Form, Field, Button, Toast, Cell, CellGroup, Checkbox, Col, Row, Radio, RadioGroup, DatetimePicker, Popup, Picker, Switch, Divider, CountDown, Icon, Search, Area } from 'vant';
Vue.use(Form).use(Field).use(Button).use(Cell).use(CellGroup).use(Checkbox).use(Row).use(Col).use(Radio).use(RadioGroup).use(DatetimePicker).use(Popup).use(Picker).use(Switch).use(Divider).use(CountDown).use(Icon).use(Search).use(Toast).use(Area);
//把dayjs挂载到vue原型
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$toast = Toast
Vue.config.productionTip = false
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')